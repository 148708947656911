import React from 'react';
import styled, { css } from 'styled-components';
import { useIntl, injectIntl } from 'gatsby-plugin-intl';
import { Col, Row } from 'react-bootstrap';
import Layout from '../components/Layout';
import Footer from '../components/Footer';
import SEO from '../components/Seo';
import Styled from '../components/styles/Title.styles';
import heroImage from '../images/nyc-background.jpg';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const BlockTitle = styled.h1`
  display: block;
  width 100%;
  color: white;
  text-align: center;
  &:first-child {
    padding-bottom: ${(props) => props.mb || 2}rem;
  }
`;

const TitleColumn = styled(Col)`
  display: flex;
  flex-direction: column;
  text-transform: uppercase; 
  justify-content: center;
  align-items: center;
`;

const Black = styled.strong`
  font-weight: black;
`;

const ParagraphContainer = styled(Row)`
  padding: 1.5em;
  & li {
    padding-bottom: 0.5em;
  }
`;

const H3 = styled.h3`
  color: #004165;
  font-size: 1.5em;
  text-align: center;
  margin-bottom: 1em;
  margin-top: 1em;
  font-weight: 300;
`;

const H4 = styled.h4`
  font-weight: bold;
  font-size: 1.1em;
  color: #004165;
`;

const SpecialIntroductoryOffer = () => (
  <Layout>
    <Wrapper>
      <Styled.Container imageUrl={heroImage} position="center">
        <TitleColumn sm={12}>
          <BlockTitle centered className="animated fadeIn">MyStay ENGLISH Launch Special Offer</BlockTitle>
          <BlockTitle as="h2" centered className="animated fadeIn">Special Offer Valid For A Limited Time</BlockTitle>
        </TitleColumn>
      </Styled.Container>
      <ParagraphContainer>
        <Col sm={12} lg={7} className="mx-auto">
          <H3 className="text-center mb-4">New Year, New You!</H3>
          <p>In 2020, live & learn with a real local, attend an approved English School. MyStay ENGLISH is available in multiple cities worldwide.</p>
          <p>MyStay ENGLISH is offering a ‘<Black>Launch Special Offer</Black>’ for all bookings paid for within the Special Offer period.</p>
          <ul>
            <li>MyStay ENGLISH Special Offer 5 week program price only <Black>$5,650 (Normally $5,950)</Black></li>
            <li>MyStay ENGLISH Special Offer 4 week program price only <Black>$4,650 (Normally $4,950)</Black></li>
          </ul>
          <H3 className="text-center mb-4">MyStay ENGLISH is THE complete English immersion package, and includes:</H3>
          <ul>
            <li>Orientation, airport transfers, English immersion (School, Online & Home Mentor), accommodation, meals (2 per day), English assessments and cultural immersion</li>
            <li>Access (12 weeks) to a globally recognised Online English language support program </li>
            <li>Entry & Exit English Assessment (& personalised score)</li>
          </ul>
          <H4 className="text-center mt-5">The Special Offer is available for a limited time only.</H4>
        </Col>
      </ParagraphContainer>
    </Wrapper>
    <Footer />
  </Layout>
);

export default injectIntl(SpecialIntroductoryOffer);
